@import "variables";
$text-color: $cw-dark-gray;

$font-size-h1: 58px;
$font-size-h2: 58px;
$font-size-h3: 34px;
$font-size-h4: 22px;

$link-color: $cw-dark-blue;
$link-hover-color: $cw-dark-blue;
$link-hover-decoration: none;

$line-height-base: 1.5;