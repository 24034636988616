// Primary colors
//
// Colors we use for higlighting states.
//
// $cw-white - #ffffff, white
// $cw-dark-blue - #1778a2, dark-blue
// $cw-blue - #28a7df, blue
//
// Styleguide Base.colors

// Gray palette
//
// Colors we use for higlighting states.
//
// $cw-light-gray - #fcfcfc, light-gray
// $cw-gray - #eeeeee, gray
// $cw-middle-gray - #cacaca, middle-gray
// $cw-middle-gray-dark - #abaaaa, middle-gray-dark
// $cw-dark-gray - #616161, dark-gray
//
// Styleguide Base.gray-colors

$cw-white: #ffffff;
$cw-gray: #eeeeee;
$cw-light-gray: #fcfcfc;
$cw-middle-gray: #cacaca;
$cw-middle-gray-dark: #abaaaa;
$cw-dark-gray: #616161;
$cw-dark-blue: #1778a2;
$cw-blue: #28a7df;

$tiny-max: 480px;
